#root app-root{
  position: absolute;
  @media #{$desktop},#{$tablet}{
    .AppMainMobileNav0,.AppMainMobileNav1{
      display: none;
    }

  }
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  min-height: 100vh;

  // SSG

  &:not(.AppSSGInit),&:not(.AppSSGInit) * {
    pointer-events: none; // This disables all click events
    user-select: none;    // This prevents text selection and interactions
    touch-action: none;   // Prevents touch-based interactions (e.g., scrolling on touch devices)
  }
  // SSG
}

#root  .AppMainMobileNav{



  &0,&1{
    .WMLMobileNavZeroPod0{
      background-color: rgba(var(--wml-orig-primary));
      flex: 0 0 75%;
    }
    .WMLSampleInfiniteDropdownItemView{
      background: var(--wml-gradient-0);
      min-height: calc(65/10.6 * 1em);
    }
    .WMLSampleInfiniteDropdownItemMainItem1{
      @include FontFamily1;
      color: rgba(var(--wml-white));
      font-size: var(--wml-display-small);
    }

    .LogoImgZeroView{

      padding: var(--wml-spacing7) ;
      @include WMLFlexColumnCenter;

      .LogoDisplayZeroMainPod{
        @include WMLFlexColumnCenter;
        padding:var(--wml-spacing3) ;
        background: var(--wml-gradient-0) ;
      }

      .LogoImgZeroMainPod{
        display: flex;
        justify-content: center;
      }

      .LogoImgZeroMainImg1{
        width: auto;
      }

    }
  }



}

