
$wml-animation-duration-0:1s;
$wml-border-radius0:calc(8/16 * 1em);
$wml-border-radius1:calc(10/16 * 1em);
$wml-border-radius2:calc(20/16 * 1em);
$wml-app-transition-time0:1s;

@mixin GlobalStrikeThru0($color: rgba(var(--wml-white))) {
  border-top: calc(1 / 16 * 1em) solid $color;
  width: 100%;
}

@mixin GlobalStrikeThru1($color: rgba(var(--wml-white))) {
  border-top: calc(1 / 16 * 1em) solid $color;
  height: 100%;
}

@mixin CursorPointer0{
  &:hover{
    cursor: pointer;
  }
}

@mixin ClipText0($gradient){
  background: $gradient;
  background-clip: text;
  color: transparent;
}
@mixin GlobalLink0 {
  cursor: pointer;
  text-decoration: underline;
  color: rgba(var(--wml-white));

  &:hover {
    text-decoration: none;
    color: rgba(var(--wml-tertiary));
  }
}


@mixin outline1 {
  border: calc(1/16 * 1em)solid red;
}

@mixin outline2 {
  border: calc(1/16 * 1em)solid blue;
}

@mixin outline3 {
  border: calc(1/16 * 1em)solid green;
}

@mixin outline4 {
  border: calc(1/16 * 1em)dotted white;
}


@mixin borderRadius0{
  border-radius: $wml-border-radius0;
}

@mixin borderRadius2{
  border-radius: $wml-border-radius2;
}

@mixin appOutline0{
  border: calc(1/16 * 1em) solid $dark-grey;
}

@mixin wmlHighlightedTitle0{
  padding:var(--wml-spacing5);
  border-radius: $wml-border-radius0;
}


@mixin FontFamily0{
  font-family: "Inter Tight", "Inter Tight Placeholder", sans-serif
}

@mixin FontFamily1{
  font-family: "Segoe UI", "Open Sans", "Helvetica Neue", sans-serif;
}

@mixin FontFamily2{
  font-family: "Poppins";
}

@mixin ModernScrollbar0{
  &::-webkit-scrollbar {
    max-width: calc(20/10.6 * 1em);
    @media #{$mobile} {
      max-width: auto;
      min-width: calc(15/10.6 * 1em);
    }
  }

  &::-webkit-scrollbar-track {
    // background: var(--wml-gradient-1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(var(--wml-black));
    border-radius: calc(20/16 * 1em);
    border: 6px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(var(--wml-black));
  }
}
