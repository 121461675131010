#root contact-zero-page{
  .WMLFormPod0:nth-child(1){
    display: flex;
    justify-content: space-between;
    .WMLFormPod0Item0{
      flex: 0 0 48%;
    }
  }

  .ContactZeroPagePod0Btn0 button{
    width: 100%;
  }
}
