


@mixin Input0{
  height: var(--wml-spacing8);
  background-color:  rgba(var(--wml-input-background-color-light-0));
  border-radius: $wml-border-radius0;
}

@mixin FormStyle0 {
  custom-label p:not(.WMLLabelMainPodError0),
  input {
    color: rgba(var(--wml-black));
  }

  wml-form {
    main {
      width: 100%;
    }
    input {
      background-color: transparent;
      border: none;
      border-radius: 0;
      width: 100%;
      height: var(--wml-spacing8);
      border-bottom: calc(1 / 16 * 1em) solid $bs-light;
    }
  }
}

@mixin FormStyle0{
  .WMLFormMainPod,.WMLFieldView,.WMLFormPod0,.WMLFormPod0Item0,.WMLFieldMainPod{
    height: 100%;
  }
  .WMLFieldView{
    display: block;
  }
  .WMLFieldMainPod{
    display: flex;
    flex-direction: column;
  }
}

@mixin MultiInputOneWMLField($max-height: 90%) {
  .WMLFieldPod1 {
    flex: 1 0 auto;
    max-height: $max-height;
  }
  @include FormStyle0;
}

@mixin MultiInputOneWMLButtonOne {
  wml-button-one{
    .WMLButtonOneMainButton {
      @for $i from 0 through 3 {
        &#{$i} {
          border-radius: calc(80/10.6 * 1em);
          padding: var(--wml-spacing3) var(--wml-spacing5);
        }
      }
    }
  }

}

@mixin Popup0{
  height: 100%;
  [class$=MainPod]{
    @include WMLFlexColumnCenter;
    background-color: rgba(var(--wml-popup-background));
    height: 100%;
    border-radius: $wml-border-radius0;
    position: relative;
    overflow: hidden auto;
    * {
      text-align: center;
    }
  }
}

@mixin Link0{
  @include CursorPointer0;
  font-family: 'Poppins' ;
  font-weight: bolder;
  font-size:var(--wml-display-xx-small);
  margin: 0 0 0 var(--wml-spacing7)
}

// dev additions
@mixin Input1{
  padding: var(--wml-spacing5);
  background: transparent;
  border-radius: 0;
  color:rgba(var(--wml-white));
  &::placeholder{
    color:rgba(var(--wml-white))
  }
}

@mixin Container0{
  width: 100%;
  border-radius: $wml-border-radius1;
  padding: var(--wml-spacing7);
  background-color: rgba(var(--wml-block-color-dark-1));

}

@mixin NavZeroMobile{
  .NavZeroPod1{
    display:none
  }
  .NavZeroPod0Icon0{
    display: block;
  }
  .NavZeroMainPod{
    padding: 0 var(--wml-spacing7);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .NavZeroPod2Icon0,.NavZeroPod2Item0,.NavZeroPod2{
    display: none;
  }
  .NavZeroPod3{
    display: flex !important;
    flex: 0 1 ;
  }
  .NavZeroPod3Icon0{
    font-size:var(--wml-display);
  }
}
@mixin NavZeroGlobal{
  .NavZeroPod3{
    display: none;
  }
}


@mixin NavOneMobile{
  .NavOneMainPod{
    border-radius: 0;
    background: none;
    padding: 0;
    align-items: center;
  }
  .NavOnePod1{
    display: none;
  }
  .NavOnePod2{
    display: block;
  }
  .NavOnePod2Icon0{
    font-size: var(--wml-display);
  }
}

@mixin NavOneGlobal{
  .NavOnePod2{
    display: none;
  }
}

@mixin JobsZeroMobile{
  .JobTitleZeroPod0{
    flex-direction: column;
    justify-content: center;
    .WMLButtonOneMainButton1{
      margin: var(--wml-spacing5) 0 0 0;
      width: 100%;
    }
  }

  .JobsZeroPod1Item1{
    flex-direction: column;

    wml-button-one{
      &:nth-child(2){
        margin: var(--wml-spacing5) 0 0 0;
      }
    }
    .WMLButtonOneMainButton2{
      width: 100%;
    }

  }


}

@mixin SharedLine0{
  margin: var(--wml-spacing9) 0 ;
  height: calc(1/10.6 * 1em);
  background-color: rgba(var(--wml-white));
}

@mixin SharedLine1{
  width:  calc(1/10.6 * 1em);
  background-color: rgba(var(--wml-white));
}

@mixin AuthPanel0{
  &Pod{
    display: flex;
    justify-content: center;
    align-items: center;
    height:100%;
  }


  &Item {
    &0 {
      width: 50%;
      height: 80%;
      background-color: rgba(var(--wml-black),.5);
      border-radius: $wml-border-radius2;
      display: flex;
      flex-direction: column;
    }
    &1{
      padding: var(--wml-spacing7);
      flex: 1 0  auto;
      display: flex;
      flex-direction: column;
      // align-items:center
    }
  }
}
