#root home-zero-page{
  @media #{$tablet}{
    .HomeZeroPagePod0Item1{
      flex-flow:row wrap;
    }
    .HomeZeroPagePod0Item2{
      flex: 0 0 50%;
      padding: var(--wml-spacing3);
    }
  }
}
