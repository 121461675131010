$wml-white: "255,255,255"; // #FFFFFF
$wml-white-filter: my-rgb-to-filter($wml-white);
$wml-black: "0,0,0"; // #000000
$wml-primary: "24,26,27"; // #181A1B
$wml-primary-filter: my-rgb-to-filter($wml-primary);
$wml-secondary: "54,58,61"; // #363A3D
$wml-secondary-filter: my-rgb-to-filter($wml-secondary);
$wml-tertiary: "16,163,127"; // #10A37F
$wml-tertiary-filter: my-rgb-to-filter($wml-tertiary);
$wml-star-selected: "255,215,0"; // #FFD700
$wml-alert: "255,0,0"; // #FF0000
$wml-google-primary:$wml-white; // #FFFFFF
$wml-facebook-primary: "58,89,152"; // #3A5998
$wml-twitter-primary: "29,161,242"; // #1DA1F2
$wml-yahoo-primary: "67,2,151"; // #430297
$wml-apple-primary:$wml-black; // #000000
$wml-microsoft-primary: "127,186,0"; // #7FBA00
$wml-github-primary: "24,23,23"; // #181717
$wml-popup-background:"112,112,112"; // #707070
$wml-success-dark:"132,225,188"; // #84E1BC
$wml-success-light:"1,71,55"; // #014737

$wml-gradient-0: radial-gradient(farthest-corner at 100% 0px, grey 0%, convert-to-rgb($wml-black) 150%);
$wml-gradient-1: radial-gradient(farthest-corner at 100% 0px, convert-to-rgb($wml-primary) 0%, convert-to-rgb($wml-secondary) 150%);
$wml-gradient-2: radial-gradient(farthest-corner at 100% 0px, convert-to-rgb($wml-white) 0%, convert-to-rgb($wml-tertiary) 150%);

// dev additions
$wml-nav-color:$wml-black; // #000000
$wml-nav-color-dark:$wml-primary; // #181A1B
$wml-input-background-color-light-0: "211,211,211"; // #D3D3D3
$wml-input-background-color-dark-0: "59,59,59"; // #3B3B3B
$wml-field-color-dark-0 :"173,216,230"; // #ADD8E6
$wml-block-color-dark-0: "31,30,36"; // #1F1E24
$wml-block-color-dark-1:"14,14,16"; // #0E0E10
// $wml-block-color-dark-1:"16,14,0"; // #100E00
// $wml-block-color-dark-0:$wml-block-color-dark-1; // #0E0E10
$wml-block-color-dark-1-filter:rgb_to_hsl($wml-block-color-dark-1); // #0E0E10
$wml-toggle-off:"34,38,41";
$wml-toggle-off-filter:rgb_to_hsl($wml-toggle-off);
$wml-toggle-on:"104,108,111";
$wml-toggle-on-filter:rgb_to_hsl($wml-toggle-on);



:root {

    --wml-field-color-dark-0:#{$wml-field-color-dark-0};
    --wml-orig-field-label-dark-0:#{$wml-field-color-dark-0};
    --wml-input-background-color-light-0:#{$wml-input-background-color-light-0};
    --wml-orig-input-background-color-light-0:#{$wml-input-background-color-light-0};
    --wml-input-background-color-dark-0:#{$wml-input-background-color-dark-0};
    --wml-orig-input-background-color-dark-0:#{$wml-input-background-color-dark-0};
    --wml-block-color-dark-0:#{$wml-block-color-dark-0};
    --wml-orig-block-color-dark-0:#{$wml-block-color-dark-0};

    --wml-white:#{$wml-white};
    --wml-orig-white:#{$wml-white};

    --wml-white-filter:#{$wml-white-filter};
    --wml-orig-white-filter:#{$wml-white-filter};
    --wml-black:#{$wml-black};
    --wml-orig-black:#{$wml-black};

    --wml-primary:#{$wml-primary};
    --wml-orig-primary:#{$wml-primary};
    --wml-primary-filter:#{$wml-primary-filter};
    --wml-orig-primary-filter:#{$wml-primary-filter};

    --wml-secondary:#{$wml-secondary};
    --wml-orig-secondary:#{$wml-secondary};
    --wml-secondary-filter:#{$wml-secondary-filter};
    --wml-orig-secondary-filter:#{$wml-secondary-filter};

    --wml-tertiary:#{$wml-tertiary};
    --wml-orig-tertiary:#{$wml-tertiary};
    --wml-tertiary-filter:#{$wml-tertiary-filter};
    --wml-orig-tertiary-filter:#{$wml-tertiary-filter};

    --wml-star-selected:#{$wml-star-selected};
    --wml-alert:#{$wml-alert};
    --wml-gradient-0:#{$wml-gradient-0};
    --wml-gradient-1:#{$wml-gradient-1};
    --wml-orig-gradient-1:#{$wml-gradient-1};
    --wml-orig-star-selected:#{$wml-star-selected};
    --wml-orig-alert:#{$wml-alert};
    --wml-orig-gradient-0:#{$wml-gradient-0};
    --wml-gradient-1:#{$wml-gradient-1};
    --wml-orig-gradient-1:#{$wml-gradient-1};


    --wml-gradient-2:#{$wml-gradient-2};
    --wml-orig-gradient-2:#{$wml-gradient-2};


    --wml-nav-color:#{$wml-nav-color};
    --wml-orig-nav-color:#{$wml-nav-color};

    --wml-popup-background:#{$wml-popup-background};
    --wml-orig-popup-background:#{$wml-popup-background};

    --wml-google-primary: #{$wml-google-primary};
    --wml-facebook-primary: #{$wml-facebook-primary};
    --wml-twitter-primary: #{$wml-twitter-primary};
    --wml-yahoo-primary: #{$wml-yahoo-primary};
    --wml-apple-primary: #{$wml-apple-primary};
    --wml-microsoft-primary: #{$wml-microsoft-primary};
    --wml-github-primary: #{$wml-github-primary};


    --wml-success-dark:#{$wml-success-dark};
    --wml-orig-success-dark:#{$wml-success-dark};
    --wml-success-light:#{$wml-success-light};
    --wml-orig-success-light:#{$wml-success-light};

    // dev additions
    --wml-block-color-dark-1:#{$wml-block-color-dark-1};
    --wml-orig-block-color-dark-1:#{$wml-block-color-dark-1};
    --wml-block-color-dark-1-filter:#{$wml-block-color-dark-1-filter};
    --wml-orig-block-color-dark-1-filter:#{$wml-block-color-dark-1-filter};


    --wml-toggle-off:#{$wml-toggle-off};
    --wml-orig-toggle-off:#{$wml-toggle-off};
    --wml-toggle-off-filter:#{$wml-toggle-off-filter};
    --wml-orig-toggle-off-filter:#{$wml-toggle-off-filter};

    --wml-toggle-on:#{$wml-toggle-on};
    --wml-orig-toggle-on:#{$wml-toggle-on};
    --wml-toggle-on-filter:#{$wml-toggle-on-filter};
    --wml-orig-toggle-on-filter:#{$wml-toggle-on-filter};

}



