
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";



// @import "node_modules/pikaday/scss/pikaday.scss";
@import "assets/styles/static.css";


@import "src/assets/styles/material-cdk-overlay.scss";
@import "src/assets/styles/global.scss";
@import "src/assets/styles/common.scss";
@import "src/assets/styles/media-queries.scss";
@import "src/assets/styles/layouts.scss";
@import "src/assets/styles/css.scss";



// app
@import "src/app/app.component.phone.scss";
@import "src/app/app.component.global.scss";

// TODO figure out a way to lazy load the css files
// not-found
@import "src/app/core/not-found/not-found.component.phone.scss";




// confirm-dialog-zero
@import "src/app/shared/components/confirm-dialog-zero/confirm-dialog-zero.component.global.scss";
@import "src/app/shared/components/confirm-dialog-zero/confirm-dialog-zero.component.phone.scss";




// logo-img-zero
@import "src/app/shared/components/logo-img-zero/logo-img-zero.component.global.scss";
@import "src/app/shared/components/logo-img-zero/logo-img-zero.component.phone.scss";





// legal-doc-page
@import "src/app/pages/legal-doc-page/legal-doc-page.component.global.scss";
@import "src/app/pages/legal-doc-page/legal-doc-page.component.phone.scss";



// survey-page
@import "src/app/pages/survey-page/survey-page.component.global.scss";
@import "src/app/pages/survey-page/survey-page.component.phone.scss";



// guide-zero-page
@import "src/app/pages/guide-zero-page/guide-zero-page.component.global.scss";
@import "src/app/pages/guide-zero-page/guide-zero-page.component.phone.scss";
@import "src/app/pages/guide-zero-page/guide-zero-page.component.tablet.scss";


// landing-zero-layout
@import "src/app/layouts/landing-zero-layout/landing-zero-layout.component.global.scss";
@import "src/app/layouts/landing-zero-layout/landing-zero-layout.component.phone.scss";

// home-zero-page
@import "src/app/pages/home-zero-page/home-zero-page.component.global.scss";
@import "src/app/pages/home-zero-page/home-zero-page.component.tablet.scss";
@import "src/app/pages/home-zero-page/home-zero-page.component.phone.scss";

// // badge-zero
// @import "src/app/shared/components/badge-zero/badge-zero.component.global.scss";
// @import "src/app/shared/components/badge-zero/badge-zero.component.phone.scss";

// nav-zero
@import "src/app/shared/components/nav-zero/nav-zero.component.global.scss";
@import "src/app/shared/components/nav-zero/nav-zero.component.phone.scss";

// threejs-background-zero
@import "src/app/shared/components/threejs-background-zero/threejs-background-zero.component.global.scss";
@import "src/app/shared/components/threejs-background-zero/threejs-background-zero.component.phone.scss";

// contact-zero-page
@import "src/app/pages/contact-zero-page/contact-zero-page.component.global.scss";
@import "src/app/pages/contact-zero-page/contact-zero-page.component.phone.scss";

// video-viewer-zero
@import "src/app/shared/components/video-viewer-zero/video-viewer-zero.component.global.scss";
@import "src/app/shared/components/video-viewer-zero/video-viewer-zero.component.phone.scss";

// card-one
@import "src/app/shared/components/card-one/card-one.component.global.scss";
@import "src/app/shared/components/card-one/card-one.component.phone.scss";


// chatbots-zero-page
@import "src/app/pages/chatbots-zero-page/chatbots-zero-page.component.global.scss";
@import "src/app/pages/chatbots-zero-page/chatbots-zero-page.component.phone.scss";

// media-query-marker (IMPT DONT DELETE THIS LINE)


:root {

  @function provideRandomColor() {
    $result: rgba(random(255), random(255), random(255), random(1));

    @return $result;
  }

  @function contrastColor($color, $dark, $light) {
    $result: if(lightness($color) < 45%, $light, $dark);

    @debug "#{$result}";
    @return var($result)
  }

  @function prefixClass($class) {
    @return ""+$class;
  }

  @function hex-to-filter($hex-color) {
    $r: red($hex
    -color);
    $g: green($hex-color);
    $b: blue($hex-color);
    $filter:  invert(100%) rgba(#{$r}, #{$g}, #{$b}, 1);
    @return $filter;
  }



  font-size: 16px;
  // previous size was 16
  color: rgba(var(--wml-white));


  @media #{$mobile}{
    font-size: calc(2vw);
  }

  @media #{$mobileFont}{
    font-size: calc(2vw);
  }


  @keyframes GlobalImg0Sping {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    z-index:5;
    @include ModernScrollbar0;
    &:not(i.fa-solid){
      @include FontFamily0;
      font-weight: 400;
    }
  }

  &:not(h1,h2,h3,h4,h5,h6,span,p){
    transition: all 1s;
  }

  h1,h2,h3,h4,h5,h6,span,p{
    transition: none;
    color: rgba(var(--wml-white))
  }

  a {
    @include GlobalLink0;
  }


  input:not([type='checkbox']),
  select,
  textarea {
    width: 100%;
  }

  input:not([type='checkbox']){

    word-wrap: break-word;
    display: block;
    font-size: inherit;
    border-radius: calc(6/16 * 1em);

    outline: none;
    margin: 0;
    padding: 0;
    transition: all 1s;

    width: 100%;
    @include FontFamily0;
  }

  textarea{

    font-size: inherit;
    border-radius: calc(6/16 * 1em);
    outline: none;
    border: calc(2/16 * 1em) inset rgb(118,118,118);
    padding: var(--wml-spacing4);
    display: block;
    margin: 0;
    min-height: calc(40/16 * 1em);
    height: calc(140/16 * 1em);
    transition: all 1s;
    width: 100%;
    resize: none;
    @include FontFamily0;
  }

  select {
    appearance: none;
  }

  li {
    list-style: none;
  }



  img{
    width: 100%;
    height: auto;
  }

  button {
    background-color: transparent;
  }

  html,body{
    background-color: rgba(var(--wml-primary));

  }

  mat-slide-toggle{
    label{
      color: rgba(var(--wml-white));
    }
  }

  h1{
    font-size:var(--wml-h1)
  }
  h2{
    font-size:var(--wml-h2)
  }
  h3{
    font-size:var(--wml-h3)
  }
  h4{
    font-size:var(--wml-h4)
  }
  h5{
    font-size:var(--wml-h5)
  }
  h6{
    font-size:var(--wml-h6)
  }
  p{
    font-size:var(--wml-p)
  }

  .skip-link {
    position: absolute;
    top: calc(-40/10.6 * 1em);
    left: 0;
    background: rgba(var(--wml-black));
    color: rgba(var(--wml-white));
    padding: calc(8/10.6 * 1em);
    z-index: 100;
    text-decoration: none;
  }

  .skip-link:focus {
    top: 0;
  }

  .WMLPopupZeroView *{
    white-space: break-spaces;
  }



}


.dg.ac{
  z-index: 10 !important;
}
