#root landing-zero-layout {
  @media #{$mobile} {
    .LandingZeroLayoutPod0{
      flex: 0 0 20%;
    }
    .NavZeroPod0 {
      flex-flow: row wrap;
      justify-content: center; 
      width: 100%;
    }
    .NavZeroMainPod {
      width: 100%;
    }
  }
}