#root home-zero-page{
  @media #{$mobile}{
    .HomeZeroPagePod0Item1{
      flex-direction:column;
    }
    .HomeZeroPagePod0Item2{
      padding-top:var(--wml-spacing7) ;
    }
  }
}
