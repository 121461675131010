
$wml-spacing0: calc(1/16* 1em);
$wml-spacing1: calc(2/16* 1em);
$wml-spacing2: calc(4/16* 1em);
$wml-spacing3: calc(8/16* 1em);
$wml-spacing4: calc(12/16* 1em);
$wml-spacing5: calc(16/16* 1em);
$wml-spacing6: calc(24/16* 1em);
$wml-spacing7: calc(32/16* 1em);
$wml-spacing8: calc(48/16* 1em);
$wml-spacing9: calc(64/16* 1em);
$wml-spacing10: calc(72/16* 1em);
$wml-spacing11: calc(96/16* 1em);
$wml-spacing12: calc(128/16* 1em);
$wml-spacing13: calc(192/16* 1em);
$wml-spacing14: calc(256/16* 1em);
$wml-spacing15: calc(384/16* 1em);
$wml-spacing16: calc(512/16* 1em);

:root{
  --wml-spacing1:#{$wml-spacing1};
  --wml-spacing2:#{$wml-spacing2};
  --wml-spacing3:#{$wml-spacing3};
  --wml-spacing4:#{$wml-spacing4};
  --wml-spacing5:#{$wml-spacing5};
  --wml-spacing6:#{$wml-spacing6};
  --wml-spacing7:#{$wml-spacing7};
  --wml-spacing8:#{$wml-spacing8};
  --wml-spacing9:#{$wml-spacing9};
  --wml-spacing10:#{$wml-spacing10};
  --wml-spacing11:#{$wml-spacing11};
  --wml-spacing12:#{$wml-spacing12};
  --wml-spacing13:#{$wml-spacing13};
  --wml-spacing14:#{$wml-spacing14};
  --wml-spacing15:#{$wml-spacing15};
  --wml-spacing16:#{$wml-spacing16};


}



