:root{
  --wml-border-radius0:#{$wml-border-radius0};
  --wml-border-radius1:#{$wml-border-radius1};
  --wml-border-radius2:#{$wml-border-radius2};
  --wml-app-transition-time0:#{$wml-app-transition-time0};
}

.glassmorphism {
  border-radius: calc(30/10.6 * 1em);
  -webkit-backdrop-filter: blur(calc(4/10.6 * 1em));
  backdrop-filter: blur(calc(4/10.6 * 1em));
  box-shadow: 0 calc(8/10.6 * 1em) calc(32/10.6 * 1em) 0 rgba(0,0,0,0.4);
  border: calc(1/10.6 * 1em) solid #ffffff2e;
}

#root{

  .WMLPanelView{
    z-index:8;
    @media #{$mobile}{
      right:-100%;
      width:100%;
    }
  }

  :not(wml-select-zero){
    .WMLSampleInfiniteDropdownItemView{
      background-color: transparent;
    }
    .WMLSampleInfiniteDropdownItemMainItem1,.WMLTableZeroDropdownItemMainPod{
      white-space: nowrap;
      // color: rgba(var(--wml-nav-color));
      // background-color: var(--wml-gradient-1);
      background-color: transparent;
      border:none;
      // font-size: var(--wml-display-small);
      white-space: normal;
      word-break: break-word;

    }

  }

  wml-form-zero{

    .WMLFieldPod0 .WMLLabelMainPodLabel0 p{
      color: rgba(var(--wml-field-color-dark-0));
    }
    wml-input-zero{
      input,textarea{
        background-color: rgba(var(--wml-primary));
        color: rgba(var(--wml-field-color-dark-0));
        font-size: var(--wml-body);
      }
    }
    button-one{
      justify-self: flex-end;
    }

    .WMLFormPod0{
      input,textarea{
        padding: var(--wml-spacing3);
      }

    }
  }

  wml-button-one{
    .WMLButtonOneMainButton{
      @for $i from 0 through 3 {
        &#{$i} {
          border-radius: calc(80/10.6 * 1em);
          padding: var(--wml-spacing5) var(--wml-spacing7);
        }
      }
      &0 {
        background-color: rgba(var(--wml-primary));
        border-color: rgba(var(--wml-white));
        @media #{$desktop}{
          &:hover{
            background-color: rgba(var(--wml-tertiary));
            border-color:transparent;
            .WMLButtonOneMainButton0Text0{
              color: rgba(var(--wml-black)) ;
            }
          }
        }
        &Selected0{
          background-color: rgba(var(--wml-tertiary));
          border-color:transparent;
          .WMLButtonOneMainButton0Text0{
            color: rgba(var(--wml-black)) ;
          }
        }
        &Text {
          &0 {
            color: rgba(var(--wml-white))
          }
        }
      }

      &1 {
        background: rgba(var(--wml-secondary));
        border-color: rgba(var(--wml-white));
        @media #{$desktop}{
          &:hover{
            filter: brightness(1.8);
            border-color:transparent;
          }
        }
        &Selected0{
          filter: brightness(1.8);
          border-color:transparent;
        }
        &Text {
          &0 {
            color: rgba(var(--wml-white))
          }
        }
      }



      &2 {


        background: rgba(var(--wml-primary));
        border-color: rgba(var(--wml-tertiary));
        @media #{$desktop}{
          &:hover{
            background-color: rgba(var(--wml-tertiary));
            .WMLButtonOneMainButton2Text0{
              color: rgba(var(--wml-black)) ;
            }
          }
        }
        &Selected0{
          background-color: rgba(var(--wml-tertiary));
          .WMLButtonOneMainButton2Text0{
            color: rgba(var(--wml-black)) ;
          }
        }
        &Text {
          &0 {
            color: rgba(var(--wml-white));
            font-weight: 700;
          }
        }
      }

      &3 {
        background-color: rgba(var(--wml-tertiary));
        border-color: rgba(var(--wml-black));
        @media #{$desktop}{
          &:hover{
            background-color: rgba(var(--wml-secondary));
            border-color: rgba(var(--wml-white));
            .WMLButtonOneMainButton0Text0{
              color: rgba(var(--wml-white)) !important;
            }
          }
        }
        .WMLButtonOneMainButton0{
          &Selected0{
            background-color: rgba(var(--wml-tertiary));
            border-color:transparent;
            .WMLButtonOneMainButton0Text0{
              color: rgba(var(--wml-black)) ;
            }
          }
          &Text {
            &0 {
              color: rgba(var(--wml-black)) !important
            }
          }
        }

      }
    }
  }

}

:root {
  --mobile-font: #{$mobileFont};
  --mobile: #{$mobile};
  --small-mobile: #{$smallMobile};
  --medium-mobile: #{$mediumMobile};
  --tablet: #{$tablet};
  --tablet-portrait: #{$tabletPortrait};
  --tablet-portrait-down: #{$tabletPortraitDown};
  --tablet-landscape: #{$tabletLandscape};
  --small-desktop: #{$smallDesktop};
  --tablet-landscape-down: #{$tabletLandscapeDown};
  --desktop: #{$desktop};
  --not-mobile: #{$notMobile};
  --laptop: #{$laptop};
  --laptop-down: #{$laptopDown};
  --laptop-tablet: #{$laptopTablet};
  --laptop-tablet-landscape: #{$laptopTabletLandscape};
  --large-desktop: #{$largeDesktop};
  --large-desktop-down: #{$largeDesktopDown};
  --xl-desktop: #{$xlDesktop};
  --xl-desktop-down: #{$xlDesktopDown};
  --xxl-desktop: #{$xxlDesktop};
  --xxxl-desktop: #{$xxxlDesktop};
  --dekstop-down-for-campaigns: #{$dekstopDownForCampaigns};
  --tablet-for-header: #{$tabletForHeader};
  --tablet-landscape-for-header: #{$tabletLandscapeForHeader};
  --tablet-landscape-down-for-header: #{$tabletLandscapeDownForHeader};
  --desktop-for-header: #{$desktopForHeader};
  --laptop-for-header: #{$laptopForHeader};
}
