#root app-root {
  @media #{$mobile}{
    height: 100dvh;
    min-height: auto !important;
    overflow: hidden auto;
    @include JobsZeroMobile;
    .WMLPopupZeroMainPod{
      height: 100%;
    }
  }

}

