#root confirm-dialog-zero{
  .WMLOptionsMainPod{
    justify-content: center;
  }

  .WMLSampleOptionItemMainBtn0{
    font-size: var(--wml-display);
  }

  h2,h3{
    white-space: break-spaces;
  }
}
